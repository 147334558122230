.App {
  height: max-content;
  position: relative;
  background-position: center;
  overflow-y: auto;
  display: flex;
  z-index: 1;
  scrollbar-width: thin;
  font-family: "DM Sans", sans-serif;
  background: #F9FBFC;
}

.authMain {
  min-width: 100%;
  height: max-content;
  background: #F9FBFC;
  overflow-y: auto;
}

.main {
  min-height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 768px) {
  .main {
    margin: 0 auto;
  }
}

.ant-alert-message {
  color: #5b667a;
}

/* total width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #dee4ef;
  border-radius: 3px;
}
::-webkit-scrollbar-track:hover {
  background-color: #dee4ef;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #dee4ef;
  border-radius: 3px;
  border: 4px solid #ed6d6f;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer-left
  > .ant-drawer-content-wrapper {
  border-radius: 0px 30px 30px 0;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-drawer .ant-drawer-content {
  border-radius: 0px 30px 30px 0;
}
